export const selectAuthUser = (state) => state.app.auth.user;

export const selectDirectories = (state) => state.settings.directories;
export const selectNomenclatureDirectories = (state) => state.settings.nomenclature.directories;
export const selectNomenclatureCategories = (state) => state.settings.nomenclature.categories;
export const selectNomenclatureProducts = (state) => state.settings.nomenclature.products;
export const selectCategoryChar = (state) => state.settings.nomenclature.categoryChar;


// Catalog
export const selectCatalogProducts = (state) => state.catalog.products;
export const selectCatalogBrands = (state) => state.catalog.brands;
export const selectCatalogAddListProduct = (state) => state.catalog.addListProduct;

// Orders
export const selectOrdersList = (state) => state.orders.list;
export const selectOrderItem = (state) => state.orders.item;
export const selectOrdersMeta = (state) => state.orders.meta;


// Users
export const selectUsersList = (state) => state.settings.users.list;
export const selectUsersStatusList = (state) => state.settings.users.settings_status;
export const selectUsersRolesList = (state) => state.settings.users.role_list;

// Bots
export const selectBotsList = (state) => state.settings.bots.list;


// Control Points Setting
export const selectControlPointsSetting = (state) => state.settings.controlPoints;

// Clients
export const selectClientsList = (state) => state.clients.list;
export const selectClientItem = (state) => state.clients.view_item;
export const selectClientItemHistory = (state) => state.clients.view_item_history;
export const selectClientsMeta = (state) => state.clients.meta;
