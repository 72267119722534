const initialState = {
    list: [],
    item: [],
    meta: {
        delivery_status: []
    }
}

const updateOrders = (state, {type, payload}) => {
    switch (type) {
        case "FETCH_ORDER_LIST":
            return {
                ...state,
                list: payload,
            };

        case "FETCH_ORDER_META":
            return {
                ...state,
                meta: payload,
            };

        case "FETCH_ORDER_ITEM":
            return {
                ...state,
                item: payload,
            };

        default:
            return state;
    }
};

const orders = (state = initialState, action) => {
    const clientState = updateOrders(state, action);
    return {
        ...clientState,
    };
};

export default orders;
