import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useSnackbar} from "notistack";
import axios from "axios";
import {
    empty,
    getEnv,
    isFnc,
    objKeys,
    objToQueryString,
    objVal, redirectToLogin,
    redirectToLogout,
} from "./functions";
import {getRequest, postRequest} from "../services/rest";
import {useDispatch, useSelector} from "react-redux";
import {loginAction, logoutAction} from "../redux/actions/auth";
import {navigate, useLocation, useNavigate} from "@reach/router";
import {route} from "../http/routes";
import queryString from "query-string";
import * as Sentry from "@sentry/react";
import {fetchAuthUserApiRoute} from "../config/api-routes";
import {setCrumbsAction, setProcessAction} from "../redux/actions/app";
import {selectSalesFolderData} from "../config/selectors";

const useHandleChange = () => {
    const [formData, setFormData] = useState();

    const handleChange = useCallback(
        (event) => {
            const target = event.target;
            formData[target.name] = target.type === "checkbox" ? target.checked : target.value;
            setFormData(Object.assign({}, formData));
        },
        [formData]
    );

    return [formData, setFormData, handleChange];
};

const useNotify = () => {
    const {enqueueSnackbar} = useSnackbar();

    return (message, status = "success", options = {}) => {
        let variant = status;
        if (typeof status == "boolean") {
            variant = status ? "success" : "error";
        }
        Object.assign(options, {
            variant: variant,
            autoHideDuration: 3000,
        });
        enqueueSnackbar(message, options);
    };
};

axios.defaults.baseURL = getEnv("REACT_APP_API_URL");

const useAxios = (url, params = {}, options = {}) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState("");
    const [loading, setloading] = useState(true);

    const fetchData = () => {
        if ("isFetch" in options && !options.isFetch) {
            return false;
        }

        getRequest(url, params)
            .then((res) => {
                setData(res);
            })
            .finally(() => {
                setloading(false);
            })
            .catch((err) => {
                setError(err);
            });
    };

    useEffect(() => {
        fetchData();
    }, []);

    return {data, error, loading};
};

const useLogoutOnTokenExpire = () => {
    // const doLogout = useLogout();
    //
    // useHandleChannelAction("logout", ({token}) => {
    //     if (localStorage.getItem("scope") === "superadmin") {
    //         return;
    //     }
    //
    //     if ((token && token !== localStorage.getItem("api_token")) || !token) {
    //         doLogout(!token);
    //     }
    // });
};

export const localStorageLogout = () => {
    localStorage.removeItem("api_token");
    localStorage.removeItem("scope");
};

const useLogout = () => {
    const dispatch = useDispatch();

    return (withRequest = true) => {
        if (withRequest) {
            getRequest("auth/logout")
                .then(() => {
                    localStorageLogout();
                    dispatch(logoutAction());
                    redirectToLogout();
                })
                .catch(() => {
                    redirectToLogout();
                });
        } else {
            localStorageLogout();
            dispatch(logoutAction());
            redirectToLogout();
        }
    };
};

const parseRouteParams = (route_params) => {
    let data = {};
    if (route_params) {
        route_params.split(";").forEach((v, _) => {
            const value = v.split(":");
            if (value?.[0]) {
                data[value[0]] = value[1];
            }
        });
    }

    return data;
};

const useLogin = () => {
    const {search} = useLocation();
    const {token, scope} = queryString.parse(search);
    const navigate = useNavigate();
    const fetchAuthUser = useFetchAuthUser();

    const getRedirectRoute = (search) => {
        const {route_name, route_params} = queryString.parse(search);

        return {
            route_name: route_name || "dashboard",
            params: route_params ? parseRouteParams(route_params) : null,
        };
    };

    return useCallback(() => {
        const redirectRoute = getRedirectRoute(search);
        const {route_name, params} = redirectRoute;

        if (!token && localStorage.getItem("api_token")) {
            navigate(route(route_name, params));
        } else {
            fetchAuthUser(token, scope, redirectRoute);
        }
    }, [search]);
};

const useBrowseQuery = () => {
    const {search} = useLocation();

    const query = useCallback(
        (key = false) => {
            const obj = queryString.parse(search);
            if (key) {
                return obj?.[key];
            }
            return obj;
        },
        [search]
    );

    const queryToString = (params) => {
        return "?" + queryString.stringify(params);
    };

    return {query, queryToString};
};

const useFetchAuthUser = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const doLogout = useLogout();

    return (apiToken, scope, redirectRoute = {}) => {
        let token = apiToken || localStorage.getItem("api_token");

        if(token == null){
            redirectToLogin()
        }

        postRequest(fetchAuthUserApiRoute, {token})
            .then((data) => {
                if (apiToken) {
                    localStorage.setItem("api_token", token);
                }

                if (scope) {
                    localStorage.setItem("scope", scope);
                }

                dispatch(loginAction(data.user));
                Sentry.setUser({...data.user});

                if (!empty(redirectRoute)) {
                    const {route_name, params} = redirectRoute;
                    navigate(route(route_name || "dashboard", params));
                }
            })
            .catch((error) => {
                doLogout();
            });
    };
};

export const useSetCustomCrumbs = () => {
    const dispatch = useDispatch();

    return (...crumbs) => {
        const arr = [];
        objVal(crumbs).forEach((name) => {
            if (name) arr.push({name})
        })
        dispatch(setCrumbsAction(arr));
    }
};

const useHandleChannelAction = (action, callback) => {
    const pusher_channel_data = useSelector((state) => state.app.pusher_channel_data);

    const isChannelAction = useMemo(() => {
        return pusher_channel_data.action === action
    }, [action, pusher_channel_data])

    useEffect(() => {
        if (isChannelAction) callback(pusher_channel_data.data);
    }, [pusher_channel_data]);
};

export const useFetch = (route, dispatchAction = false) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const fetchRequest = (params) => {
        return new Promise((resolve) => {
            setLoading(true);
            getRequest(route, params).then((result) => {
                if (isFnc(dispatchAction)) {
                    dispatch(dispatchAction(result));
                } else {
                    setData(result);
                    resolve(result);
                }
                setLoading(false);
            });
        })
    };

    return {fetchRequest, setData, data, loading};
};

export const usePostRequest = (route, showLoading = false) => {
    const notify = useNotify()
    const dispatch = useDispatch()

    return (data) => {
        if (showLoading) dispatch(setProcessAction(true))
        return new Promise((resolve, reject) => {
            postRequest(route, data).then((resp) => {
                if (showLoading) dispatch(setProcessAction(false))
                if (resp.message) {
                    notify(resp.message, resp.status)
                }
                resolve(resp)
            }).catch(reject)
        })
    }
};

export const useDeleteRequest = (route) => {
    const notify = useNotify();

    return (id, callback) => {
        postRequest(route, {id}).then((props) => {
            const {status, message} = props;
            notify(message, status);
            if (isFnc(callback)) callback(props);
        });
    };
};

export const useSortingRequest = (route) => {
    const notify = useNotify();
    return (data) => {
        postRequest(route, data).then(({status, message}) => {
            notify(message, status);
        });
    };
};

export const usePushQueryParams = () => {
    const location = useLocation();

    return useCallback(
        (newParams) => {
            const queryParams = Object.assign({}, queryString.parse(location.search), newParams);
            if (!objKeys(queryParams)) return;
            navigate(`${location.pathname}?${objToQueryString(queryParams)}`);
        },
        [location]
    );
};

export const useResize = () => {
    const [widthScreen, setWidthScreen] = useState(window.innerWidth);
    const [heightScreen, setHeightScreen] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = (event) => {
            setWidthScreen(event.target.innerWidth);
            setHeightScreen(event.target.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return {
        widthScreen,
        heightScreen
    };
};

export {
    useHandleChange,
    useNotify,
    useAxios,
    useLogoutOnTokenExpire,
    useLogout,
    useBrowseQuery,
    useFetchAuthUser,
    useHandleChannelAction,
    useLogin,
};
