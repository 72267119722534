const updateControlPoint = (state, {type, payload}) => {
    switch (type) {
        case "FETCH_CONTROL_POINT_SETTINGS":
            return {
                ...state,
                settings: payload,
            };

        default:
            return state;
    }
};

export default updateControlPoint;
