const updateUsers = (state, {type, payload}) => {
    switch (type) {
        case "FETCH_USERS_LIST":
            return {
                ...state,
                list: payload,
            };

        case "FETCH_USERS_STATUS_LIST":
            return {
                ...state,
                settings_status: payload,
            };

        case "FETCH_USERS_ROLES_LIST":
            return {
                ...state,
                role_list: payload,
            };

        default:
            return state;
    }
};

export default updateUsers;
