import {objVal} from "../../../helpers/functions";

const initialState = {
    products: [],
    brands: [],
    addListProduct: []
}

const updateCatalog = (state, {type, ...payload}) => {
    switch (type) {
        case "FETCH_CATALOG_PRODUCTS":
            return {
                ...state,
                products: objVal(payload).slice(),
            };

        case "FETCH_CATALOG_BRAND":
            return {
                ...state,
                brands: payload,
            };

        case "FETCH_ADD_PRODUCT":
            return {
                ...state,
                addListProduct: objVal(payload).slice(),
            };

        case "CLEAR_ADD_PRODUCT":
            return {
                ...state,
                addListProduct: [],
            };

        default:
            return state;
    }
};

const catalog = (state = initialState, action) => {
    const catalogState = updateCatalog(state, action);
    return {
        ...catalogState,
    };
};

export default catalog;
