const initialState = {
    menu: [
        {
            name: "Витрина",
            path: "/dashboard/home/",
            isDrop: false
        },
        {
            name: "Заказы",
            path: "/dashboard/orders/",
            isDrop: true
        },
        {
            name: "Клиенты",
            path: "/dashboard/clients/",
            isDrop: false
        },
        {
            name: "Настройки",
            path: "/dashboard/settings/",
            isDrop: true,
            child: [
                {
                    name: "Справочник",
                    path: "/dashboard/settings/directory",
                },
                {
                    name: "Номенклатура",
                    path: "/dashboard/settings/nomenclature",
                },
                {
                    name: "Пользователи",
                    path: "/dashboard/settings/users",
                },
                {
                    name: "Бот",
                    path: "/dashboard/settings/bots",
                }
            ]
        }
    ],
    open_pop_up: false,
    toggle_main_menu: true,
    redirect_route: {
        route_name: 'home',
        params: []
    },
    auth: {
        user: {},
        isAuth: null,
    },

    process: false,
}

const updateMainStates = (state = initialState, {type, payload}) => {
    switch (type) {
        case "FETCH_GLOBAL_DATA":
            return {
                ...state,
                ...payload,
            };

        case "CHANGE_POPUP":
            return {
                ...state,
                open_pop_up: payload,
            };

        default:
            return state;
    }
};

const updateAuthStates = (state = initialState, {type, payload}) => {
    switch (type) {
        case "FETCH_USER":
            const status = Object.keys(payload).length;
            return {
                isAuth: !!status,
                user: payload,
            };

        case "LOGOUT":
            return {
                isAuth: false,
                user: {},
            };

        case "LOGIN":
            return {
                isAuth: true,
                user: payload,
            };

        default:
            return state.auth;
    }
};

const app = (state = initialState, action) => {
    const mainStates = updateMainStates(state, action);
    const authStates = updateAuthStates(state, action);

    return {
        ...mainStates,
        auth: authStates,
    };
};

export default app;
