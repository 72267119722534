const initialState = {
    list: [],
    view_item: {},
    view_item_history: [],
    meta: {
        payment_type: [],
        users_status: [],
    }
}

const updateClients = (state, {type, payload}) => {
    switch (type) {
        case "FETCH_CLIENTS_LIST":
            return {
                ...state,
                list: payload,
            };

        case "FETCH_CLIENT_ITEM":
            return {
                ...state,
                view_item: payload,
            };

        case "FETCH_CLIENT_ITEM_HISTORY":
            return {
                ...state,
                view_item_history: payload,
            };

        case "FETCH_CLIENTS_META":
            return {
                ...state,
                meta: payload,
            };

        default:
            return state;
    }
};

const clients = (state = initialState, action) => {
    const clientState = updateClients(state, action);
    return {
        ...clientState,
    };
};

export default clients;
