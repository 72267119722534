const updateBots = (state, {type, payload}) => {
    switch (type) {
        case "FETCH_LIST_BOTS":
            return {
                ...state,
                list: payload,
            };

        default:
            return state;
    }
};

export default updateBots;
