import React from "react";
import {MainRouter} from "./http/routes/index";
import {Provider as ReduxProvider} from "react-redux";
import {store} from "./redux";
import {AppProvider} from "./providers";
import {render} from "react-dom";
import {ThemeProvider} from "@mui/material/styles";
import theme from "./config/theme";
import {Layout} from "./components/admin_layout";
import {Location} from "@reach/router";
import {SnackbarProvider} from "notistack";


const Root = () => {
    return (
        <ThemeProvider theme={theme}>
            <ReduxProvider store={store}>
                    <Location>
                        <AppProvider>
                                <SnackbarProvider maxSnack={1}>
                                    <Layout>
                                        <MainRouter />
                                    </Layout>
                                </SnackbarProvider>
                        </AppProvider>
                    </Location>
            </ReduxProvider>
        </ThemeProvider>
    );
};


render(<Root />, document.getElementById("root"));
