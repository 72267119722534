const updateDirectories = (state, {type, payload}) => {
    switch (type) {
        case "FETCH_LIST_DIRECTORY":
            return {
                ...state,
                data: payload,
            };

        default:
            return state;
    }
};

export default updateDirectories;
