import updateDirectories from "./directory";
import updateNomenclature from "./nomenclature";
import updateUsers from "./users";
import updateBots from "./bots";
import updateControlPoint from "./control-points";


const initialState = {
    directories: {
        data: []
    },
    nomenclature: {
        products: [],
        categories: [],
        categoryChar: [],
        directories: [],
    },
    users: {
        list: [],
        settings_status: [],
        role_list: [],
    },
    bots: {
        list: [],
    },

    controlPoints: {
        settings: null
    }
}


const settings = (state = initialState, action) => {
    return {
        directories: updateDirectories(state.directories, action),
        nomenclature: updateNomenclature(state.nomenclature, action),
        users: updateUsers(state.users, action),
        bots: updateBots(state.bots, action),
        controlPoints: updateControlPoint(state.controlPoints, action),
    };
};

export default settings;
