import React, {useEffect} from "react";
import {AppContextProvider} from "./context";
import {useFetchGlobalData} from "./hooks";
import {navigate, useLocation} from "@reach/router";
import {useSelector} from "react-redux";

const AppProvider = ({children}) => {
    const isAuth = useSelector((state) => state.app.auth.isAuth);
    const fetchGlobalData = useFetchGlobalData()

    const location = useLocation();

    useEffect(() => {
        if (!isAuth) return;

        fetchGlobalData()
    }, [isAuth]);

    useEffect(() => {
        if (location.pathname.indexOf("sales") !== -1) {
            navigate(location.pathname);
        }
    }, []);

    return <AppContextProvider>{children}</AppContextProvider>;
};

export default AppProvider;
