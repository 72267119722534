const updateNomenclature = (state, {type, payload}) => {
    switch (type) {
        case "FETCH_NOMENCLATURE_PRODUCT_LIST":
            return {
                ...state,
                products: payload,
            };

        case "FETCH_LIST_NOMENCLATURE_CATEGORY":
            return {
                ...state,
                categories: payload,
            };

        case "FETCH_LIST_NOMENCLATURE_DIRECTORY":
            return {
                ...state,
                directories: payload,
            };

        case "FETCH_CATEGORY_CHAR_LIST":
            return {
                ...state,
                categoryChar: payload,
            };

        default:
            return state;
    }
};

export default updateNomenclature;
