import React from "react";
import {Box} from "@mui/material";
import Icon from "../icon";
import {createStyles, makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) =>
    createStyles({
        container: {
            padding: "32px 24px 24px 24px",
            borderBottom: "1px solid rgba(255, 255, 255, 0.05)"
        },
        logo: {
            width: "100%",
            maxHeight: "100px",
            objectFit: "contain"
        }
    })
);

const Logo = () => {
    const classList = useStyles();

    return (
        <Box className={`${classList.container}`}>
            <Icon name="logo" pointer className={`${classList.logo}`} />
        </Box>
    );
}

export default Logo;
