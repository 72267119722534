export const fetchAuthUserApiRoute = "auth/login-oauth";
export const fetchLogoutApiRoute = "auth/logout";

/*Home page*/
export const fetchHomeApiRoute = "home/fetch-list";


/*Settings Directory*/
export const fetchListDirectoriesApiRoute = "settings/directory/list";
export const addOrEditCharApiRoute = "settings/directory/add-or-edit-char";
export const sortCharApiRoute = "settings/directory/sort";
export const deleteCharApiRoute = "settings/directory/delete";

/*Settings Nomenclature*/
export const fetchNomenclatureListCategoryApiRoute = "settings/nomenclature/fetch-list-category";
export const fetchNomenclatureCategoryCharApiRoute = "settings/nomenclature/fetch-category-char";
export const fetchNomenclatureProductApiRoute = "settings/nomenclature/product-list";
export const fetchNomenclatureDirectoriesApiRoute = "settings/nomenclature/directory";
export const addOrEditNomenclatureCategoryApiRoute = "settings/nomenclature/add-or-edit-category";
export const addOrEditNomenclatureProductApiRoute = "settings/nomenclature/add-or-edit-product";
export const deleteProductApiRoute = "settings/nomenclature/delete-product";
export const deleteCategoryApiRoute = "settings/nomenclature/delete-category";
export const sortNomenclatureApiRoute = "settings/nomenclature/sort";


/* Catalog */
export const fetchCatalogBrandApiRoute = "catalog/fetch-brand";
export const fetchCatalogProductsApiRoute = "catalog/fetch-products";
export const fetchAddListProductApiRoute = "catalog/add/fetch-list-product";
export const fetchSaveListProductApiRoute = "catalog/add/save";
export const fetchSaveItemProductApiRoute = "catalog/add/save-item";
export const fetchSavePercentApiRoute = "catalog/add-percent";
export const fetchUploadPriceListApiRoute = "catalog/upload-price-list";

export const sortCatalogProductApiRoute = "catalog/sort";

/* Orders */
export const fetchOrderMetaApiRoute = "orders/meta";
export const fetchOrderListApiRoute = "orders/list";
export const fetchOrderItemApiRoute = "orders/item";
export const changeOrderStatusApiRoute = "orders/change-status";

/* Settings Users */
export const fetchSettingsUsersApiRoute = "settings/users/fetch-list";
export const fetchSettingsUsersStatusApiRoute = "settings/users/fetch-user-status-list";
export const fetchSettingsUsersRolesApiRoute = "settings/users/fetch-user-roles-list";
export const changeUserStatusApiRoute = "settings/users/change-status";
export const editUserDataApiRoute = "settings/users/edit";
export const deleteUserDataApiRoute = "settings/users/delete";

/* Settings Bots */
export const fetchBotsListApiRoute = "settings/bots/fetch-list";
export const changeBotStatusApiRoute = "settings/bots/change-status";

/* Control Points */
export const fetchControlPointSettingApiRoute = "settings/control-point/setting";
export const changeControlPointApiRoute = "settings/control-point/change";

/* Settings Clients */
export const fetchClientsMetaApiRoute = "clients/fetch-meta";
export const fetchClientsUsersApiRoute = "clients/fetch-list";
export const fetchClientItemApiRoute = "clients/fetch-item";
export const fetchClientItemHistoryApiRoute = "clients/fetch-history";
export const changeClientApiRoute = "clients/change";
export const sendMessageApiRoute = "clients/send-message";
export const changeClientsStatusApiRoute = "clients/change-status";
export const changeClientsPaymentTypeApiRoute = "clients/change-payment-type";
export const deleteClientDataApiRoute = "clients/delete";
